import React from "react";
import Layout from "layout";
import { Container, Row, Col } from "react-grid-system";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import heroImage from "../images/eczema/a-tub-and-bottle-of-eucerin-advanced-repair-lotion-and-cream.png";
import img from "../images/eczema/a-bottle-of-advanced-repair-lotion.png";
import img1 from "../images/eczema/a-tub-of-eucerin-advanced-repair-cream.png";
import imgOatmeal from "../images/icons/circle-icons-with-nmfs-and-ceramide-np.jpg";
import imgCeramide from "../images/icons/circle-icons-with-nmfs-ceramide-np-and-cocoa-butter.jpg";

import img4 from "../images/eczema/a-woman-applying-lotion-to-the-upper-arm.jpg";
import img5 from "../images/eczema/teardrop-icon-with-advanced-repair-formulation.png";
import img9 from "../images/eczema/skin-illustration.png";
import { TypeA, TypeB } from "common/callouts";

import icon1 from "../images/icons/circle-icon-with-a-teal-shower-head.png";
import icon2 from "../images/icons/circle-icon-with-two-teal-water-drops.png";
import icon3 from "../images/icons/circle-icon-with-a-teal-bottle.png";
import icon4 from "../images/icons/circle-icon-with-a-teal-sun.png";
import icon5 from "../images/icons/circle-icon-with-a-teal-sponge.png";
import icon6 from "../images/icons/circle-with-a-teal-hand-and-bubbles.png";

import {
    ProductTypeA,
    ProductTypeB,
    ProductTypeC,
} from "common/product-details";

import "./index.scss";

function HomePage() {
    const deviceSizes = useDeviceSize();

    return (
        <Layout pageClass="home">
            <Seo pageTitle="Advanced Repair" pageDescription="Learn about Eucerin's therapeutic solutions for eczema" />


            {/* HERO SECTION */}
            <section className="fluid-section red-gradient-bg hero-banner">
                <div className="inner-centered-container">
                    <div className="hero-container">
                        <Row>
                            <Col md={12} lg={7} className={deviceSizes?.smUp ? "" : ""} order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                                <ProductTypeC productAlt="A tube of Eucerin Eczema Relief Cream and a bottle of Eczema Relief Cream body wash">
                                    <div className="functions">
                                        <h4>For all-over daily moisturizing:</h4>
                                        <h1>Go beyond ceramides</h1>
                                        <p>
                                            <span className="mid-content">Eucerin<sup>&reg;</sup> Advanced Repair is uniquely formulated to replenish what nature provided to help treat dry skin.</span>
                                        </p>
                                        <ul className="checkmarks teal">
                                            <li>
                                                <span>FRAGRANCE-FREE</span>
                                            </li>
                                            <li>
                                                <span>DYE-FREE</span>
                                            </li>
                                        </ul>
                                        <ul className="checkmarks teal">
                                            <li>
                                                <span> PARABEN-FREE</span>
                                            </li>
                                            <li>
                                                <span>NON-COMEDOGENIC</span>
                                            </li>
                                        </ul>
                                    </div>
                                </ProductTypeC>
                            </Col>
                            <Col md={12} lg={5} className={deviceSizes?.smUp ? "" : ""} order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                                <div className="cout-products">
                                    <img
                                        src={heroImage}
                                        alt="A bottle of Eucerin Baby Eczema Relief Cream & Wash next to a tube of Eucerin Baby Eczema Relief Cream"
                                        className="hero-product"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>

             {/* FORMULATION SECTION */}
             <div className="inner-body-content formulation-block">
                <section className="inner-centered-container">
                    <ProductTypeA
                        categoryBgColor="#455F71"
                        categoryName="THE FORMULATION"
                        categoryFontColor="#6acad5"
                        productName="A complete therapeutic approach to dry skin"
                        productAlt=" Teardrop icon with Advanced Repair formulation"
                        productImage={img5}
                    >
                        <div className="functions">
                            <p>
                            Ceramides alone aren’t enough to treat dry skin. That’s where Advanced Repair comes in. The unique formulation is designed to address the three key issues of a damaged moisture barrier by replenishing what the skin needs:
                            </p>
                            <ul className="dblarrows red">
                                <li>Natural moisturizing factors help improve and maintain skin hydration</li>
                                <li>
                                Ceramide NP helps strengthen the skin's barrier and defends against water loss
                                </li>                               
                            </ul>
                        </div>
                    </ProductTypeA>
                </section>
            </div>


            {/* LINK-BUTTON SECTION 1 */}
            <section className="inner-centered-container callout-with-links-bullets">
                <Container fluid>
                    <Row className="justify-center">
                        <Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <img src={img} alt="A bottle of Advanced Repair Lotion" className="" />
                        </Col>
                        <Col sm={12} md={7} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <h2>
                                Eucerin<sup>&reg;</sup> Advanced Repair Lotion
                            </h2>
                            <ul className="dblarrows red">
                                <li>
                                    <span>
                                        Provides immediate and lasting hydration for up to 48 hours
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Lightweight, daily hydration
                                    </span>
                                </li>
                            </ul>
                            <div className="links-wrap">
                                <span className="font-bold dblue-text mb-10">Buy now at:</span>
                                <br />
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Advanced-Repair-Skin-Lotion/dp/B003BMJGKE/ref=sr_1_2?crid=1SNSANF8G1CSL&keywords=advanced+repair+lotion&qid=1678221023&sprefix=advanced+repair+lotion%2Caps%2C125&sr=8-2" target="_blank">Amazon</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Advanced-Repair-Body-Lotion-16-9-Fl-Oz-Pump-Bottle/10811050?athbdg=L1102&adsRedirect=true" target="_blank">Walmart</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.target.com/p/eucerin-advanced-repair-unscented-body-lotion-for-dry-skin-16-9-fl-oz/-/A-11005178#lnk=sametab" target="_blank">Target</a>
                                    </li>
                                </ul>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-advanced-repair-body-lotion-13-5-oz-prodid-361273" target="_blank">CVS</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-advanced-repair-body-lotion/ID=prod3970669-product" target="_blank">Walgreens</a>
                                    </li>
                                </ul>

                                <div className="icon-content">
                                    <span className="icon-text">ENRICHED WITH:</span>
                                    <div class="img-wrapper full-w">
                                        <img
                                            src={imgOatmeal}
                                            alt="Icons of Colloidal oatmeal and Ceramide NP in red circles"
                                            className=""
                                        />
                                        {/*<span className="plus">+</span>
                                        <img
                                            src={imgCeramide}
                                            alt="Circle icon representing Ceramide NP"
                                            className=""
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="inner-centered-container">
                <hr></hr>
            </section>

            {/* LINK-BUTTON SECTION 2 */}
            <section className="inner-centered-container callout-with-links-bullets callout-with-links-bullets--alt ">
                <Container fluid>
                    <Row className="justify-center">
                        <Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <img src={img1} alt=" A tub of Eucerin Advanced Repair Cream" className="link-sec-2-img" />
                        </Col>
                        <Col sm={12} md={7} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <h2>
                                Eucerin<sup>&reg;</sup> Advanced Repair Cream
                            </h2>
                            <ul className="dblarrows red">
                                <li>
                                    <span>
                                        Provides immediate and lasting hydration for up to 48 hours
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Formulated for daily use to help moisturize dry skin
                                    </span>
                                </li>
                            </ul>
                            <div className="links-wrap">
                                <span className="font-bold dblue-text mb-10">Buy now at:</span>
                                <br />
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Advanced-Repair-Creme-Packaging/dp/B01DIXHNUU/ref=sr_1_2?crid=3SWBYOM5NAE7D&keywords=advanced+repair+cream&qid=1678221041&sprefix=advanced+repair+cream%2Caps%2C116&sr=8-2" target="_blank">Amazon</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Advanced-Repair-Body-Cream-Body-Cream-for-Dry-Skin-16-Oz-Jar/105094113?athbdg=L1102&adsRedirect=true" target="_blank">Walmart</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.target.com/p/eucerin-advanced-repair-fragrance-free-body-cream-for-dry-skin-16oz/-/A-76525854#lnk=sametab" target="_blank">Target</a>
                                    </li>
                                </ul>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-advanced-repair-cream-jar-12-oz-prodid-481138" target="_blank">CVS</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-advanced-repair-cream-jar/ID=prod6339569-product" target="_blank">Walgreens</a>
                                    </li>
                                </ul>

                                <div className="icon-content">
                                    <span className="icon-text">ENRICHED WITH:</span>
                                    <div class="img-wrapper full-w">                                      
                                        <img
                                            src={imgCeramide}
                                            alt="Circle icons with NMFs, Ceramide NP and Cocoa Butter"
                                            className=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* TREATMENT GOAL SECTION */}
            <div className="inner-body-content treatment-block">
                <section className="inner-centered-container treatment">
                    <ProductTypeA
                        categoryBgColor="#455F71"
                        categoryName="Treatment Goals"
                        categoryFontColor="#6acad5"
                        productName="Understanding your dry skin"
                        productAlt=" A woman applying lotion to the upper arm"
                        productImage={img4}
                    >
                        <div className="functions">
                            <p>
                                Your doctor will review the specific treatment goals for your dry
                                skin condition, but they may include:
                            </p>
                            <ul className="dblarrows red">
                                <li>Restoring moisture and maintaining lasting hydration</li>
                                <li>
                                    Strengthening the skin’s natural moisture barrier
                                </li>
                                <li>Relieving symptoms (flaking, scaling, itching, rough patches)</li>
                                <li>Establishing a skincare routine to replenish dry skin daily</li>
                            </ul>
                        </div>
                    </ProductTypeA>
                </section>
            </div>

            {/* THINGS TO DO SECTION */}

            <div className="custom-title">
                <div className="inner-body-content inner-centered-container">
                    <ProductTypeA
                        categoryBgColor="#455F71"
                        categoryName="HEALTHY HABITS"
                        categoryFontColor="#6acad5"
                        productName="Healthy habits to maintain healthy-looking (and feeling) skin                  "
                    >
                    </ProductTypeA>
                </div>
            </div>

            <section className="callout-container inner-centered-container">
                <div className="inner-body-content">
                    <Row>
                        <Col sm={12} md={12} order={{ xs: 2, md: 1 }} className="">
                            <TypeB
                                className="icon-wrap"
                                headName="Things to do:"
                                headFontColor="#455F71"
                                quoteContent="Excessive bathing, especially in hot water, washes away the skin’s natural protective oils and dehydrates the skin.">
                                <Container fluid>
                                    <Row>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon1} alt="Circle icon with a teal shower head"></img>
                                            </div>
                                            <p>
                                                Limit showers and baths to 10 minutes a day using lukewarm water
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon2} alt="Circle icon with two teal water drops"></img>
                                            </div>
                                            <p>
                                                Moisturize immediately after bathing
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon3} alt="Circle icon with a teal bottle"></img>
                                            </div>
                                            <p>
                                                Use mild, fragrance-free products
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                            </TypeB>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* THINGS NOT TO USE  SECTION */}

            <section className="callout-container inner-centered-container my-75 icon-section">
                <div className=" inner-body-content">
                    <Row>
                        <Col sm={12} md={12} order={{ xs: 2, md: 1 }} className="">
                            <TypeB
                                className="icon-wrap"
                                headName="THINGS TO AVOID:"
                                headFontColor="#455F71"
                                quoteContent="Prolonged sun exposure is one of the most damaging environmental factors for skin–and can lead to dryness, wrinkles, and skin cancer.">
                                <Container fluid className="wraps">
                                    <Row>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon4} alt="Circle icon with a teal sun"></img>
                                            </div>
                                            <p>
                                                Too much sun exposure
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon5} alt="Circle icon with a teal sponge"></img>
                                            </div>
                                            <p>
                                                Overbathing and overexfoliating
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon6} alt="Circle with a teal hand and bubbles"></img>
                                            </div>
                                            <p>Harsh cleansers, soaps, and detergents</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </TypeB>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* SURFACE  ON SKIN */}

            <section className="callout-container fluid-section darkgray-gradient-bg full-with-section fluid-section--alt">
                <div className="inner-container">
                    <Row className="align-center">
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "pl-50" : ""} order={{ xs: 2, md: 1 }}>
                            <ProductTypeC
                                categoryFontColor="#ffffff"
                                productName="The causes–and contributing factors–of dry skin">
                                <div className="functions">
                                    <h2>
                                    Dry skin can happen to anyone, but you have a higher likelihood of developing it if you:
                                    </h2>
                                    <ul className="dblarrows white">
                                        <li>Are 40+ years of age</li>
                                        <li>
                                        Live in certain climates (cold, windy, low humidity)
                                        </li>
                                        <li>Have a job that requires frequent handwashing</li>
                                    </ul>
                                    <span className="font-18">View the <a href="#" className="custom-link">full list</a> of dry skin risk factors.</span>
                                </div>
                            </ProductTypeC>
                        </Col>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "no-pr" : ""} order={{ xs: 1, md: 2 }}>
                            <div className="cout-img">
                                <img
                                    src={img9}
                                    alt="Skin illustration"
                                    className="image"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
       
            <section className="inner-centered-container my-75 custom-m">
                <hr></hr>
            </section>

        </Layout>
    );
}

export default HomePage;
